import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(duration)
dayjs.extend(customParseFormat)
dayjs.extend(relativeTime)

export const isDateBefore = (date: Date | string, compareDate: Date | string) => {
  return dayjs(date).isBefore(compareDate)
}

export const isDateAfter = (date: Date | string, compareDate: Date | string) => {
  return dayjs(date).isAfter(compareDate)
}

export const getTimeDiffFormatted = (startTime: Date | string, endTime: Date | string) => {
  const start = dayjs(startTime)
  const end = dayjs(endTime)
  const duration = dayjs.duration(end.diff(start))

  const minutesLeft = Math.floor(Number(duration.asMinutes()))
  const secondsLeft = Math.floor(Number(duration.asSeconds())) % 60
  const timeDiffFormatted =
    `${minutesLeft < 10 ? `0${minutesLeft}` : minutesLeft}` +
    ':' +
    `${secondsLeft < 10 ? `0${secondsLeft}` : secondsLeft}`

  return timeDiffFormatted
}

export const secondsUntilTime = (endTime: Date | string) => {
  const end = dayjs(endTime)
  const now = dayjs()
  const duration = dayjs.duration(end.diff(now))
  return duration.asSeconds()
}

export const secondsToTime = (seconds: number) => {
  return dayjs.duration(seconds, 'seconds').format('mm:ss')
}

export const isValidDateFormat = (formattedDate: string, format: string) => {
  return dayjs(formattedDate, format, true).isValid()
}

export const parseDate = (formattedDate: string, format: string) => {
  return dayjs(formattedDate, format, true).toDate()
}

export const formatDate = (date: dayjs.ConfigType, format: string) => {
  return dayjs(date).format(format)
}

export const relativeTimeToNow = (date: dayjs.ConfigType) => {
  return dayjs(date).toNow(true)
}
