import { CohartImageSizeTypes, CohartImageSizes } from 'types/Image'
import { ArtworkDetailsImage } from 'types/V3/Artwork'
import { UserLocation } from 'types/V3/User'
import { isDateBefore } from './datetime'

export const getArtworkDisplayImage = (images: ArtworkDetailsImage[]) => {
  const displayImage = images.find((image) => image.isMain) || images[0]
  return displayImage
}

export const getCohartImageUrl = (
  imageSizes: CohartImageSizes | undefined,
  size: keyof CohartImageSizeTypes,
  fallbackUrl = '/images/image-not-found.png',
) => {
  if (!imageSizes?.imageOriginal) return fallbackUrl // if no original image, other sizes likely don't exist
  const urlOriginal = imageSizes.imageOriginal
  const urlSmall = imageSizes.imageSmall
  const urlMedium = imageSizes.imageMedium
  const urlLarge = imageSizes.imageLarge
  const urlFull = imageSizes.imageFull

  if (size === 'imageOriginal') return urlOriginal || fallbackUrl
  if (size === 'imageSmall') return urlSmall || urlOriginal || fallbackUrl
  if (size === 'imageMedium') return urlMedium || urlSmall || urlOriginal || fallbackUrl
  if (size === 'imageLarge') return urlLarge || urlMedium || urlSmall || urlOriginal || fallbackUrl
  if (size === 'imageFull') return urlFull || urlLarge || urlMedium || urlSmall || urlOriginal || fallbackUrl
  return fallbackUrl
}

// show address modal if user has not filled out address, and artwork is for sale
export const shouldShowAddressModal = (myProfile?: UserLocation) => {
  if (!myProfile) return false
  if (!myProfile.countryIso) return true
  if (!myProfile.addressLineFirst) return true
  if (!myProfile.postalCode) return true
  return false // don't show address modal
}

export function validateSaleDate(saleDate: Date | null) {
  if (saleDate === null) return { message: 'Date sold is required to add sales data to profile', isValid: false }
  // sale date must be in the past
  const now = new Date()
  if (!isDateBefore(saleDate, now)) {
    return { message: 'Date sold have to be in the past', isValid: false }
  }

  return { message: '', isValid: true }
}
