import { MutableRefObject, useEffect, useRef } from 'react'

function useValueAsRef<T>(propValue: T): MutableRefObject<T> {
  const ref = useRef(propValue)
  useEffect(() => {
    ref.current = propValue
  }, [propValue])

  return ref
}

export default useValueAsRef
