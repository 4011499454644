import useSWRInfiniteHelper from 'hooks/swr/useSWRInfiniteHelper'
import useSWRInfinite from 'swr/infinite'
import { useCallback, useRef } from 'react'
import { makeMomentsSwrKey } from 'constants/swrKeys'
import { NewBackEndApiResponseData } from 'types/ApiResponseData'
import { withApiErrorHandled } from 'utils/common'
import { apiClient } from 'utils/apiClient'
import { MomentBase } from 'types/Moment'
import useValueAsRef from 'hooks/common/useValueAsRef'

type ResponseType = NewBackEndApiResponseData<{ list: Array<MomentBase> }>

const useFetchingMoments = (username: string, isLoggedIn: boolean, pageSize = 10) => {
  const isLoadingRef = useRef(false)
  const getKey = useCallback(
    (pageIndex: number) => makeMomentsSwrKey(pageIndex + 1, pageSize, username, isLoggedIn),
    [isLoggedIn, username, pageSize],
  )

  const fetcher = useCallback(async (swrKey: string) => {
    if (!swrKey) return []
    isLoadingRef.current = true

    // fetch data
    const response = await withApiErrorHandled(apiClient.get<ResponseType>)(swrKey)
    const { data, ok } = response.data

    isLoadingRef.current = false
    return ok ? data.list : []
  }, [])

  const swrResponse = useSWRInfinite(getKey, fetcher, { revalidateOnFocus: false })

  const { data, size, setSize, isValidating } = swrResponse
  const { isEmpty, isLoadingFirstPage, isLoadingMore, loadMore, isReachingEnd } = useSWRInfiniteHelper(swrResponse, {
    pageSize: 10,
  })

  const loadMoreRef = useValueAsRef(loadMore)
  const conditionLoadMoreRef = useRef(() => {
    if (isLoadingRef.current) return // prevent multiple requests
    loadMoreRef.current()
  })

  return {
    data,
    isLoadingInitialData: isLoadingFirstPage,
    isLoadingMore,
    isEmpty,
    isReachingEnd,
    isValidating,
    size,
    setSize,
    onLoadMore: loadMore,
    conditionLoadMoreRef,
  }
}

export default useFetchingMoments
