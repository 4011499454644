import { HeartIcon } from '@heroicons/react/24/outline'
import { PlayIcon } from '@heroicons/react/24/solid'
import classNames from 'classnames'
import RoundedAvatarImage from 'shared/components/avatar/RoundedAvatarImage'
import CustomImage from 'shared/components/CustomImage'
import Link from 'next/link'
import { useMemo } from 'react'
import { MomentBase, MomentContent } from 'types/Moment'
import NameWithVerificationBadge from '../display/NameWithVerificationBadge'
import { CommentIcon } from 'shared/icons/CommentIcon'
import { getCohartImageUrl } from 'utils/artwork'

type Props = {
  moment: MomentBase
  toggleQRModal: () => void
  hideAuthorInfo?: boolean
}

const MomentCard = (props: Props) => {
  const { moment, toggleQRModal, hideAuthorInfo } = props
  const momentItem = useMemo(() => {
    switch (moment.type) {
      case 'video':
        if (moment.contents.length === 0) return null
        if (moment.contents[0].video === null) return null
        return (
          <Link passHref key={moment.id} href={`/u/${moment.user.username}/videos/${moment.contents[0].video?.id}`}>
            <MomentCardUI
              type="video"
              authorAvatar={moment.user.coverImage || ''}
              authorFullName={moment.user.fullName}
              authorIsVerified={moment.user.isVerified}
              image={moment.contents[0].video.processedThumb || ''}
              imageDescription={moment.contents[0].video.description}
              caption={moment.caption}
              likeCounts={moment.likeCounts}
              hideAuthorInfo={hideAuthorInfo}
              commentCounts={moment.commentCounts}
              artworkPreview={moment.contents[0].video.artworkPreview}
            />
          </Link>
        )
      case 'image':
        if (moment.contents.length === 0) return null
        return (
          <MomentCardUI
            key={moment.id}
            type="image"
            authorAvatar={moment.user.coverImage || ''}
            authorFullName={moment.user.fullName}
            authorIsVerified={moment.user.isVerified}
            image={moment.contents[0].image.imageMedium || ''}
            imageDescription={moment.caption}
            caption={moment.caption}
            onClick={toggleQRModal}
            likeCounts={moment.likeCounts}
            commentCounts={moment.commentCounts}
            hideAuthorInfo={hideAuthorInfo}
          />
        )
      case 'artwork_sharing':
      case 'reshared':
      case 'text':
        if (!moment.caption) return null
        return (
          <MomentCardUI
            key={moment.id}
            type={moment.type}
            authorAvatar={moment.user.coverImage || ''}
            authorFullName={moment.user.fullName}
            authorIsVerified={moment.user.isVerified}
            caption={moment.caption}
            onClick={toggleQRModal}
            likeCounts={moment.likeCounts}
            hideAuthorInfo={hideAuthorInfo}
            commentCounts={moment.commentCounts}
          />
        )
      default:
        return null
    }
  }, [moment, toggleQRModal, hideAuthorInfo])

  return <>{momentItem}</>
}

export default MomentCard

type MomentVideoOrImageCardProps = {
  type: 'video' | 'image'
  image: string
  imageDescription: string
}

type MomentOthersCardProps = {
  type: 'text' | 'artwork_sharing' | 'reshared'
}

type MomentCardUIProps = {
  commentCounts: number
  likeCounts: number
  authorAvatar: string
  authorFullName: string
  authorIsVerified: boolean
  caption: string
  onClick?: () => void
  hideAuthorInfo?: boolean
  artworkPreview?: MomentContent['video']['artworkPreview']
} & (MomentVideoOrImageCardProps | MomentOthersCardProps)

const MomentCardUI = (prop: MomentCardUIProps) => {
  const {
    authorAvatar,
    authorFullName,
    authorIsVerified,
    caption,
    onClick,
    type,
    likeCounts,
    hideAuthorInfo,
    commentCounts,
    artworkPreview,
  } = prop

  const isVideoMoment = type === 'video'
  const isPhotoMoment = type === 'image'
  const isTextMoment = type === 'text'
  const isResharedMoment = type === 'reshared' || type === 'artwork_sharing'
  const isPhotoOrVideo = isVideoMoment || isPhotoMoment

  const authorInfo = useMemo(() => {
    if (hideAuthorInfo) return null
    return (
      <div className="inline-flex w-full items-center gap-1">
        <RoundedAvatarImage className="size-4" src={authorAvatar} alt={authorFullName} />
        <NameWithVerificationBadge
          fullName={authorFullName}
          isVerified={authorIsVerified}
          className="text-xs font-medium text-black/60"
          maxNameLength={14}
        />
      </div>
    )
  }, [authorAvatar, authorFullName, authorIsVerified, hideAuthorInfo])

  const isEmptyVideoThumbnail = type === 'video' && !prop.image

  return (
    <div
      className={classNames(
        'h-full w-full cursor-pointer rounded-2xl border border-black/10 ',
        'transition-shadow hover:shadow-lg',
      )}
      onClick={onClick}
    >
      <div className="aspect-[4/5] w-full">
        {isPhotoOrVideo && (
          <div className="relative h-full w-full flex-1 overflow-hidden rounded-t-2xl">
            {!isEmptyVideoThumbnail && (
              <CustomImage src={prop.image} fill className="object-cover" alt={prop.imageDescription} />
            )}
            {isEmptyVideoThumbnail && (
              <div className="flex h-full w-full items-center justify-center bg-black px-2 text-sm">
                <p className="text-center text-white">
                  The video is being processed.
                  <br />
                  Come check later when it is ready!
                </p>
              </div>
            )}
            {type === 'video' && !isEmptyVideoThumbnail && (
              <div className="absolute left-1/2 top-1/2 z-[2] -translate-x-1/2 -translate-y-1/2">
                <PlayIcon className="size-[21px] text-white" />
              </div>
            )}
            {/* artwork thumbnail if the video is linked to an artwork */}
            {isVideoMoment && artworkPreview && (
              <div className="absolute bottom-[12px] right-[12px] aspect-artwork h-1/3">
                <div className="relative h-full w-full overflow-hidden rounded-lg">
                  <CustomImage
                    src={getCohartImageUrl(artworkPreview, 'imageMedium')}
                    blurDataURL={getCohartImageUrl(artworkPreview, 'imageSmall', '/images/placeholder.webp')}
                    placeholder="blur"
                    fill
                    alt={artworkPreview.title}
                    className="object-cover object-center"
                  />
                </div>
              </div>
            )}
          </div>
        )}
        {(isTextMoment || isResharedMoment) && (
          <div className="flex h-full w-full flex-col gap-2 p-[12px]">
            {/* author info */}
            {authorInfo}
            {/* caption */}
            <p className="w-full flex-1 overflow-hidden text-ellipsis text-[16px] font-medium leading-[1.2] text-black/60">
              {caption}
            </p>
          </div>
        )}
      </div>

      <div className="space-y-2 p-[12px]">
        {/* author info */}
        {isPhotoOrVideo && authorInfo}

        {/* like counts */}
        <div className="flex w-full flex-row-reverse items-center justify-between gap-[1ch] empty:hidden">
          {/* like counts */}
          <div className="flex gap-2">
            <div className="inline-flex items-center gap-1 text-kokushoku-black">
              <HeartIcon className="size-[14px]" />
              <span className="text-[10px] leading-[1.2] tracking-wide">{likeCounts}</span>
            </div>

            <div className="inline-flex items-center gap-1 text-kokushoku-black">
              <CommentIcon className="size-[14px]" />
              <span className="text-[10px] leading-[1.2] tracking-wide">{commentCounts}</span>
            </div>
          </div>

          {/* title */}
          {isPhotoOrVideo && (
            <div className="line-clamp-1 flex-1 text-[12px] font-medium leading-[1.2] text-black/60">{caption}</div>
          )}
        </div>
      </div>
    </div>
  )
}
