import { MomentBase } from 'types/Moment'

const checkIfValidMoment = (moment: MomentBase) => {
  // filter out empty moments
  if (['image', 'video'].includes(moment.type) && moment.contents?.length) {
    return true
  }

  if (['artwork_sharing', 'reshared', 'text'].includes(moment.type) && moment.caption) {
    return true
  }
  return false
}

export default checkIfValidMoment
