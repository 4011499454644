export const CommentIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <span data-nosnippet>
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_14609_8648)">
        <path
          d="M7.17578 8.33203H14.2869"
          stroke="#191414"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.17578 11.8867H12.5091"
          stroke="#191414"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.0677 3.88672C16.775 3.88672 17.4532 4.16767 17.9533 4.66777C18.4534 5.16786 18.7344 5.84614 18.7344 6.55339V13.6645C18.7344 14.3717 18.4534 15.05 17.9533 15.5501C17.4532 16.0502 16.775 16.3312 16.0677 16.3312H11.6233L7.17882 18.9978V16.3312H5.40104C4.6938 16.3312 4.01552 16.0502 3.51542 15.5501C3.01533 15.05 2.73438 14.3717 2.73438 13.6645V6.55339C2.73438 5.84614 3.01533 5.16786 3.51542 4.66777C4.01552 4.16767 4.6938 3.88672 5.40104 3.88672H16.0677Z"
          stroke="#191414"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_14609_8648">
          <rect width="21.3333" height="21.3333" fill="white" transform="translate(0.0664062 0.333008)" />
        </clipPath>
      </defs>
    </svg>
  </span>
)
