import { useCallback } from 'react'
import { SWRInfiniteResponse } from 'swr/infinite'

type GetSWRInfiniteStateOptions = {
  pageSize: number
}
const useSWRInfiniteHelper = (swrResponse: SWRInfiniteResponse, options: GetSWRInfiniteStateOptions) => {
  const { isLoading, data, setSize } = swrResponse
  const { pageSize } = options || {}
  const isEmpty = data?.[0]?.length === 0 && !isLoading // the first page is empty and loading has finished
  const isLoadingFirstPage = typeof data?.[0] === 'undefined' && isLoading // is loading and no data on the first page
  const isLoadingMore = Boolean(data?.[0]?.length) && isLoading // is loading and there is data on the first page
  const isReachingEnd = isEmpty || Boolean(data && data[data.length - 1]?.length < pageSize) // the list is empty or the last page has less items than the page size
  const loadMore = useCallback(() => {
    if (isReachingEnd) return
    if (isLoading) return
    setSize((size) => size + 1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isReachingEnd])

  return {
    isEmpty,
    isLoading, // is loading any page
    isReachingEnd,
    loadMore,
    isLoadingFirstPage,
    isLoadingMore,
  }
}

export default useSWRInfiniteHelper
